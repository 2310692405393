/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import * as auth from '../redux/AuthRedux'
import { register } from '../redux/AuthCRUD'
import { Link } from 'react-router-dom'
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  phoneNumber: '',
  organizationName: '',
  changepassword: '',
  acceptTerms: false,
}

const symbols = ['-', '.', '_', '@', +"'", '#', '!', '/', '^', '%', '{', '}', '*', '"'];
// eslint-disable-next-line
const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  password: Yup.string()
    .required('Password is required')
    .test('PasswordPolicy', 'Not secure.', (pwd) => {
      return pwd !== undefined && pwd !== null && pwd.length >= 8 &&
        pwd.toLowerCase() !== pwd && symbols.some(x => pwd.includes(x.toString()));
    }),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .test('PhoneNumberPolicy', 'Not valid.', (phoneNumber) => {
      return phoneNumber !== undefined && phoneNumber !== null && phoneRegex.test(phoneNumber);
    }),
  organizationName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Organization name is required'),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setTimeout(() => {
        register(values.email, values.firstname, values.lastname, values.phoneNumber, values.password, true, values.organizationName)
          .then(({ data: { api_token } }) => {
            setLoading(false)
            setStatus({ type: 'success', message: 'Registration completed.' })
            dispatch(auth.actions.register(api_token))
          })
          .catch((error) => {
            setLoading(false)
            setSubmitting(false)
            setStatus({ type: 'danger', message: error.response && error.response.data && error.response.data.error ? error.response.data.error : 'Unexpected error.' })
          })
      }, 1000)
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='mb-10 text-center'>
        <h1 className='text-dark mb-3'>Create an Account</h1>
      </div>

      {formik.status && (
        <div className={`mb-lg-15 alert alert-${formik.status.type}`}>
          <div className='alert-text font-weight-bold'>{formik.status.message}</div>
        </div>
      )}

      <div className='row fv-row mb-7'>
        <div className='col-lg-6'>
          <label className='form-label fw-bolder text-dark fs-6'>First name</label>
          <input
            placeholder='First name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstname')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.firstname && formik.errors.firstname,
              },
              {
                'is-valid': formik.touched.firstname && !formik.errors.firstname,
              }
            )}
          />
        </div>
        <div className='col-lg-6'>
          <label className='form-label fw-bolder text-dark fs-6'>Last name</label>
          <input
            placeholder='Last name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('lastname')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.lastname && formik.errors.lastname,
              },
              {
                'is-valid': formik.touched.lastname && !formik.errors.lastname,
              }
            )}
          />
        </div>
      </div>

      <div className='row fv-row mb-7'>
        <div className='col-lg-12'>
          <label className='form-label fw-bolder text-dark fs-6'>Email</label>
          <input
            placeholder='Email'
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
        </div>
      </div>

      <div className='row fv-row mb-7'>
        <div className='col-lg-12'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
          </div>

        </div>
        <div className="text-muted">
          Use 8 or more chars with a mix of letters, numbers & symbols.
        </div>
      </div>

      <div className='row fv-row mb-7'>
        <div className='col-lg-12'>
          <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
          <input
            type='password'
            placeholder='Password confirmation'
            autoComplete='off'
            {...formik.getFieldProps('changepassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
              },
              {
                'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
              }
            )}
          />
        </div>
      </div>


      <div className='row fv-row mb-7'>
        <div className='col-lg-6'>
          <label className='form-label fw-bolder text-dark fs-6'>Phone</label>
          <input
            placeholder='Phone'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('phoneNumber')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber },
              {
                'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber,
              }
            )}
          />
        </div>
        <div className='col-lg-6'>
          <label className='form-label fw-bolder text-dark fs-6'>Organization</label>
          <input
            placeholder='Organization'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('organizationName')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.organizationName && formik.errors.organizationName },
              {
                'is-valid': formik.touched.organizationName && !formik.errors.organizationName,
              }
            )}
          />
        </div>
      </div>

      <div className='row fv-row mb-7'>
        <div className='col-lg-12'>
          <div className='form-check form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              id='kt_login_toc_agree'
              {...formik.getFieldProps('acceptTerms')}
            />
            <label
              className='form-check-label fw-bold text-gray-700 fs-6'
              htmlFor='kt_login_toc_agree'
            >
              I Agree the{' '}
              <Link to='/auth/terms' className='ms-1 link-primary'>
                terms and conditions
              </Link>
              .
            </label>
            {formik.touched.acceptTerms && formik.errors.acceptTerms && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.acceptTerms}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
